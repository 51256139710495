import { Grid } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import { PrimaryHeading } from '../../components/PrimaryHeading'
// import { querySelf } from '../../api/queries/querySelf.query'
import { SecondaryHeading, Text } from '../../foundations/Text'
import { useAuth0 } from '@auth0/auth0-react'
// import { useMutation, useQuery } from 'react-query'
import React, { useState } from 'react'
import { Variants, motion } from "framer-motion"
import LicensePlateSearch from "../../components/dashboard/LicensePlateSearch"
// import { useProtectedRoute } from '../../utils/useProtectedRoute'
import { ActionCard } from '../../components/ActionCard'
import { faRocket } from '@fortawesome/free-solid-svg-icons'
import { useReactPWAInstall } from "react-pwa-install";
import CheckLogoPng from "../../assets/checkLogo.png"
import { queryTrafficStop } from '../../api/queries/queryTrafficStop.query'
import { Modal } from '../../foundations/Modal'
import { useProtectedRoute } from "../../utils/useProtectedRoute"

const container: Variants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
}

const item: Variants = {
  hidden: { opacity: 0, x: -200 },
  show: { opacity: 1, x: 0 }
}


export const DashboardPage: React.FC = () => {

  const history = useHistory()
  const { isAuthenticated, getAccessTokenSilently, isLoading: isAuthLoading, error: authError, loginWithRedirect } = useAuth0()
  const [isModalOpen, setIsModalOpen] = useState(false)
  
//   let {
//     isLoading,
//     data
//   } = useQuery('self', querySelf({ getAccessTokenSilently }), {
//     enabled: isAuthenticated,
//     onError: (error: any) => toast.error(error?.message),
//     onSuccess: (data: any) => {
//       const hasBasicInfo = (data.firstName && data.lastName && data.email)
//       if (!hasBasicInfo) {
//         history.push("/registration")
//       }
//     }
//   })

//   useProtectedRoute()
//   const {
//     data: userData,
//   } = useQuery('self', querySelf({ getAccessTokenSilently }), {
//     enabled: isAuthenticated
//   })

  useProtectedRoute()

  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const UserNotFoundModal = () => (
    <Modal isModalOpen={isModalOpen} onRequestClose={() => {
      setIsModalOpen(false)
    }} >
        <Grid pb={4} py={3}>
          <PrimaryHeading style={{ textAlign: "center" }}>
            Driver Doesn&apos;t Have Check
          </PrimaryHeading>
          <Text textAlign="center">
            This traffic stop is gonna have to happen to old fashioned way.
          </Text>
        </Grid>
    </Modal>
  )

  return (
    <>
      <UserNotFoundModal />
      {
        // isLoading || isAuthLoading ? (
        //   <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
        //     <Spinner strokeWidth={1} size={96} color="primary" />
        //   </Flex>
        // ) : (
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
          >
            <Grid pb={4} sx={{ gap: 3 }}>
              <PrimaryHeading>
                Welcome to Check!
              </PrimaryHeading>
              <Text textAlign="center">
                The Virtual Traffic Stop App
              </Text>
            </Grid>
            <Grid  sx={{ gap: 8 }}>
              <motion.div variants={item}>
                <LicensePlateSearch onNotifyClick={async (plate) => {
                  const response = await queryTrafficStop({ plate })
                  .catch((e) => {
                    setIsModalOpen(true)
                  })

                  if (!!response?.roomToken) {
                    history.push(`/traffic-stop?code=${response?.roomToken}`)
                  }
                }}/>
              </motion.div>
              {
                (!isInstalled && supported && false) && (
                  <motion.div variants={item}>
                    <ActionCard
                      onClick={() => {
                        pwaInstall({
                          title: "Install Check",
                          logo: CheckLogoPng
                        })
                      }}
                      stepText="Step 2"
                      title="Add to Phone"
                      details="Be sure to add Check to your homescreen for quick access."
                      buttonText="Add to Homescreen"
                      icon={faRocket}
                    />
                  </motion.div>
                )
              }
            </Grid>
          </motion.div>
        // )
      }
    </>
  )
}