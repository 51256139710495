import styled from "@emotion/styled"
import { selectSpace } from "./tokens"

export const Card = styled.div`
  padding: 24px;
  /* border: #ddd thin solid; */
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
  /* display: flex;
  flex-direction: column;
  gap: 24px; */
  background: white;
  width: 100%;
`

// styled.div`
//   padding: ${selectSpace(6)};
//   /* border: #ddd thin solid; */
//   border-radius: ${selectSpace(2)};
//   box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
//   display: flex;
//   flex-direction: column;
//   gap: ${selectSpace(6)};
//   background: white;
//   width: 100%;
// `