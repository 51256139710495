import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

import { getOperatingSystem, getBrowser} from './utils/platform'

import { PageLayout } from "./components/Layout"

import { Card, Alert, Box } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { DashboardPage } from "./pages/dashboard/Dashboard.page"
import { TrafficStopPage } from "./pages/TrafficStop.page"
import { useProtectedRoute } from "./utils/useProtectedRoute"

export const SlugContext = React.createContext("")


export const App: React.FC = () => {

  const [show, setShow] = useState(true)

  // useProtectedRoute()
  
  useEffect(() => {
    if (getOperatingSystem() === 'iOS' && getBrowser() !== 'Safari') {
      setShow(false)
    }
  }, [])

  return (
    <Router>
      <PageLayout>
        <>
          <Switch>
            <Route path="/dashboard" component={DashboardPage} />
            <Route path="/traffic-stop" component={TrafficStopPage} />
            <Route path="/"><Redirect to="/dashboard" /></Route>
          </Switch>
          
        </>
      </PageLayout>
    </Router>
  )
}