import { getRequest } from ".."

export interface QueryConsentOpts {
  policeToken: string
}

export const queryConsent = async ({ policeToken }: QueryConsentOpts) => {
  const url = `/police/stop/consent/${policeToken}`
  const response = await getRequest<any>(url, {})
  return response
}