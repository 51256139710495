import styled from '@emotion/styled'
import { DesignTokens, selectFontSize } from './tokens'

interface _BaseTextProps {
  theme?: DesignTokens,
  fontSize?: number,
  textAlign?: "left" | "center" | "right"
}

export const Text = styled.p<_BaseTextProps>`
  font-size: ${props => props.fontSize ? selectFontSize(props.fontSize)(props) : selectFontSize(2)(props)};
  text-align: ${props => props.textAlign ?? "inherit"};
  line-height: 1.5;
`

export const Heading = styled.h2<_BaseTextProps>`
  font-size: ${props => props.fontSize ? selectFontSize(props.fontSize)(props) : selectFontSize(4)(props)};
  text-align: ${props => props.textAlign ?? "inherit"};
`
export const SecondaryHeading = styled.h3<_BaseTextProps>`
  font-size: ${props => props.fontSize ? selectFontSize(props.fontSize)(props) : selectFontSize(3)(props)};
  font-weight: 300;
  text-align: ${props => props.textAlign ?? "inherit"};
`
