import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"

export const useProtectedRoute = () => {

  const { isAuthenticated, isLoading: isAuthLoading, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated && !isAuthLoading) {
      loginWithRedirect()
    }
  }, [isAuthLoading, isAuthenticated])
}