import React from 'react'
import { Grid, Heading, Text } from 'theme-ui'

export interface VehicleInfoProps {
  data: any
}

export const VehicleInfo = ({ data }: VehicleInfoProps) => {
  return (
    <Grid>
      <Heading variant="secondary">
        Vehicle Information
      </Heading>
      <Heading variant="tertiary">
        Quick Info
        </Heading>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Plate
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.plate}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          State
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.state}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Make
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.make}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Model
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.model}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Vin
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.vin}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Year
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.year}
        </Text>
      </Grid>
    </Grid>
  )
}