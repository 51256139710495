import React, { useEffect, useState, VideoHTMLAttributes } from 'react'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { AspectRatio, Box, Button, Flex, Grid, Heading, Image, Spinner, Text } from '@theme-ui/components'
import queryString from 'query-string'
import { motion } from "framer-motion"

import { connect, RemoteTrack, RemoteParticipant, RemoteAudioTrack, RemoteVideoTrack } from "twilio-video"
import { useRef } from 'react'
import { useQuery } from 'react-query'
import { queryConsent } from '../api/queries/queryConsent.query'
import { queryActualToken } from '../api/queries/queryActualToken.query'
import { VehicleInfo } from "../components/VehicleInfo"
import { InsuranceInfo } from "../components/InsuranceInfo"


const trackpubsToTracks = (trackMap: any) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication?.track)
      .filter((track) => track !== null);


enum CallState {
  INIT = "INIT",
  LOADING = "LOADING",
  CONNECTED = "CONNECTED"
}

export const TrafficStopPage = () => {

  const { search } = useLocation()
  const parsed = queryString.parse(search)
  const [code, setCode] = useState<string>(parsed?.code as string)

  const [data, setData] = useState<any>({});
  const [consentGiven, setConsentGiven] = useState(null)

  const [callState, setCallState] = useState<CallState>(CallState.INIT)

  const [videoTracks, setVideoTracks] = useState<RemoteVideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<RemoteAudioTrack[]>([]);

  const [participant, setParticipant] = useState<RemoteParticipant>()

  const videoRef = useRef<any>();
  const audioRef = useRef<any>();

  const [consentTimer, setConsentTimer] = useState<number>(0)
  let consentInterval: NodeJS.Timeout;

  const handleStartCall = async (data: {shortAlias: string, actualToken: string}) => {
    console.log(data.actualToken)
    const room = await connect(data.actualToken, {
      audio: true,
      video: true
    })

    // make sure to render video track
    room.participants.forEach(participant => {
      console.log({ participant })
      setParticipant(participant)
    })

    // make sure to render video track
    room.on("participantConnected", participant => {
      setParticipant(participant)
    })
    room.on("participantDisconnected", value => console.log("participantDisconnected", value))
    room.on("participantReconnected", setParticipant)
    room.on("participantReconnecting", value => console.log("participantReconnecting", value))
    room.on("trackMessage", value => console.log("trackMessage", value))

    setCallState(CallState.LOADING)
  }

  useEffect(() => {
    if (participant) {
      setVideoTracks(tracks => [...tracks, ...trackpubsToTracks(participant?.videoTracks)]);
      setAudioTracks(tracks => [...tracks, ...trackpubsToTracks(participant?.audioTracks)]);
  
      const trackSubscribed = (track: RemoteTrack) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => [...audioTracks, track]);
        }
      };
  
      const trackUnsubscribed = (track: RemoteTrack) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
        }
      };
  
      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);
  
      return () => {
        setVideoTracks([]);
        setAudioTracks([]);
        participant.removeAllListeners();
      };
    }
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[videoTracks.length - 1];

    setCallState((prevState) => {
      if (prevState === CallState.INIT) {
        return prevState
      }
      return videoTrack ? CallState.CONNECTED : CallState.LOADING
    })

    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    console.log(code)
    ;(async function init() {
      console.log(code)

      consentInterval = setInterval(async () => {
        const consentResult = await queryConsent({ policeToken: code })
        setConsentTimer(consentTimer + 5)
        console.log(consentResult)
  
        if (consentResult.consentGiven !== null) {
          setConsentGiven(consentResult.consentGiven);
          setData(consentResult.driverInfo ?? {});
          clearInterval(consentInterval)
        }
      }, 5000)
    })()
  }, [code])

  useEffect(() => {
    ;(async function init() {
      if (consentGiven) {
        console.log(consentGiven + ': init call')
        const data = await queryActualToken({ shortAlias: code as string })

        handleStartCall(data)
      }
    })()
  },[consentGiven])

  useEffect(() => {
    console.log(consentTimer)
    if (consentTimer >= 50) {
      clearInterval(consentInterval)
    }
  }, [consentTimer])

  return (
    <>
    { consentGiven === null &&
      <Grid gap={4}>
        <Heading>
          Waiting for user to give consent
        </Heading>
        <Text variant="data-value">
          Once user consents to a virtual traffic stop, a video feed will be connected and user info will be displayed.
        </Text>
      </Grid>
    }
    { consentGiven === false &&
      <Grid gap={4}>
        <Heading>
          User did not consent
        </Heading>
        <Text variant="data-value">
          The user did not consent to the virtual stop, please continue in person.
        </Text>
      </Grid>
    }
    { consentGiven &&
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Grid gap={4}>
          {
            callState === CallState.INIT || callState === CallState.LOADING &&
              <>
                  <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
                      <Spinner strokeWidth={1} size={96} color="primary" />
                  </Flex>
              </>
          }
          <Grid sx={{
            opacity: callState === CallState.CONNECTED ? 1 : 0,
            transition: "opacity 200ms",
            gap: 4
          }}>
            {/* <Heading sx={{ textAlign: "center" }}>
              You&apos;re watching {parsed.driver}!
            </Heading> */}
            <AspectRatio ratio={16 / 9} sx={{
              width: "100%",
              bg: "#333",
              borderRadius: "8px",
              overflow: "hidden",
              
              "> video": {
                height: "100%",
                width: "100%",
                objectFit: "contain"
              }
            }}>
              <video ref={videoRef} autoPlay={true}/>
            </AspectRatio>
          </Grid>
          <audio ref={audioRef} autoPlay={true} />
        </Grid>
        <Grid gap={4}>
          <Heading>
          Drivers Info
          </Heading>
          <Heading variant="secondary">
          Drivers License
          </Heading>
          <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
            <Heading variant="data-label" as="h3">
                Drivers License Number
            </Heading>
            <Text variant="data-value">
                {data?.driversLicense?.number}
            </Text>
          </Grid>
          <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
            <Heading variant="data-label" as="h3">
                First Name
            </Heading>
            <Text variant="data-value">
                {data?.firstName}
            </Text>
          </Grid>
          <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
            <Heading variant="data-label" as="h3">
                Last Name
            </Heading>
            <Text variant="data-value">
                {data?.lastName}
            </Text>
          </Grid>
          <Box sx={{
          borderRadius: "8px",
          overflow: "hidden"
          }}>
              <Image
                  sx={{
                      display: "block"
                  }}
                  src={data?.driversLicense?.imageUrl}
              />
          </Box>
        </Grid >
        {data?.vehicles?.[0] && <VehicleInfo data={data}/>}
        {data?.insurance?.insuranceCardImageUrl && <InsuranceInfo data={data}/>}
      </motion.div>
    }
    </>
  )
}

function useInput<T>(foundCode: string) {
    throw new Error('Function not implemented.')
}
