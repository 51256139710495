import { useAuth0 } from "@auth0/auth0-react"
import AppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import AccountCircle from "@material-ui/icons/AccountCircle"
import React from "react"

import Logo from "../assets/checkLogo.png"
import { Link, useHistory, useLocation } from "react-router-dom"
import { Box, Flex, Grid, Image, Styled } from "theme-ui"
import { Button } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import { motion, AnimatePresence } from "framer-motion"
import { useEffect } from "react"
import { useState } from "react"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textAlign: "center",
    },
    appBar: {
      background: "#fff",
      color: "#000",
    },
    logo: {
      height: "2em",
    },
  })
)

const _faArrowLeft = faArrowLeft as IconProp

export const Topbar = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { logout, user, isAuthenticated } = useAuth0()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const [isAlreadyMounted, setIsAlreadyMounted] = useState<boolean>(false)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logOut = async () => {
    logout({ returnTo: window.location.origin })
  }

  const includesDashboard = location.pathname.includes("dashboard")
  console.log({ includesDashboard })
  const showBackButton = !includesDashboard && isAuthenticated

  return (
    <React.Fragment>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Toolbar>
          <Grid sx={{ width: "100%", justifyContent: "space-between", alignContent: "center", alignItems: "center"}} columns="32px auto 32px">
            <Box>
              <AnimatePresence>
                {
                  showBackButton && (
                    <motion.div
                      key="back-button"
                      initial={{ x: -100 }}
                      animate={{ x: 0 }}
                      exit={{ x: -100 }}
                    >
                      <Button variant="ghost" sx={{ bg: "white", color: "black" }} onClick={history.goBack}>
                        <FontAwesomeIcon icon={_faArrowLeft}/>
                      </Button>
                    </motion.div>
                  )
                }
              </AnimatePresence>
            </Box>
            <Box as={props => <Link {...props} to="/dashboard"/>}>
              <AnimatePresence>

                <motion.div
                  initial={ isAlreadyMounted ? false : {
                    y: -200
                  }}
                  animate={{
                    y: 0
                  }}
                  onAnimationComplete={() => {
                    setIsAlreadyMounted(true)
                  }}
                >
                  <Flex sx={{ alignItems: "center" }}>
                    <Image src={Logo} width={"180px"} />
                  </Flex>
                </motion.div>
              </AnimatePresence>
            </Box>
            <div>
              <AnimatePresence>
                {isAuthenticated && (
                  <motion.div
                    key="profile-button"
                    initial={{ x: 100 }}
                    animate={{ x: 0 }}
                    exit={{ x: 100 }}
                  >
                    <IconButton
                      aria-label="account of current user"
                      edge="start"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </motion.div>
                )}
              </AnimatePresence>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>Vehicles</MenuItem> */}
                <MenuItem onClick={() => history.push("/digital-drivers-card")}>Profile</MenuItem>
                <MenuItem onClick={logOut}>Logout</MenuItem>
              </Menu>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default Topbar
