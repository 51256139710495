import { useCallback, useState } from "react"
import ReactModal from "react-modal"
import { GhostButton } from "./Buttons"
import { Card } from "./Card"

import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Container } from "../components/Layout"
import { Box, Flex } from "reflexbox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCross, faTimes } from "@fortawesome/free-solid-svg-icons"

const StyledModal = styled(ReactModal)`
  /* display: "relative"; */
  outline: none;
  width: 100%;
`

interface ModalProps {
  isModalOpen: boolean,
  onRequestClose: () => void
}

const _faTimes = faTimes as IconProp

export const Modal: React.FC<ModalProps> = ({ children, isModalOpen = false, onRequestClose }) => {

  return (
    <StyledModal
      isOpen={isModalOpen}
      onRequestClose={onRequestClose}
      contentLabel={"test"}
      style={{
        overlay: {
          background: "rgba(0,0,0,0.10)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }
      }}
    >
      <Container>
        <ModalCard>
          <ModalCloseButton onClick={onRequestClose} compact>
            <FontAwesomeIcon icon={_faTimes}/>
          </ModalCloseButton>
          <Box width="100%">{ children }</Box>
        </ModalCard>
      </Container>
    </StyledModal>
  )
}

const ModalCard = styled(Card)`
  position: relative;
`

const ModalCloseButton = styled(GhostButton)`
  position: absolute;
  top: 0;
  right: 0;
`