import { Theme } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"

// https://theme-ui.com/theme-spec

// core scaler type

type Scale<T extends Object> = Array<string> & Partial<T>

// alias types

type SpaceAlias = 'small' | 'medium' | 'large'
type TypographyAlias = 'body' | 'heading' | 'monospace'
type ColorAlias = 'text' | 'background' | 'primary' | 'secondary' | 'accent' | 'muted'

// tokens

// const space: Scale<Record<SpaceAlias, string>> = (new Array(100)).fill("").map((v, index) => {
//   if (index === 0) {
//     return `0px`
//   } else {
//     return `${Math.pow(2, index + 1).toString()}px`
//   }
// })

const space: Scale<Record<SpaceAlias, string>> = (new Array(100)).fill("").map((v, index) => `${4 * index}px`)

space.small = space[1]
space.medium = space[2]
space.large = space[3]

const breakpoints: Scale<{}> = ['40rem', '52rem', '64rem']
const mediaQueries = {
  ">phone": `@media screen and (min-width: ${breakpoints[0]})`,
  ">tablet": `@media screen and (min-width: ${breakpoints[1]})`,
  ">desktop": `@media screen and (min-width: ${breakpoints[2]})`
}

const fontSizes: Scale<{}> = [12, 16, 20, 24, 32, 48, 64].map(v => `${v}px`)

const colors: Record<ColorAlias, string> = {
  text: '#000',
  background: '#fff',
  primary: '#4D66F2',
  secondary: '#05a',
  muted: '#f6f6f6',
  accent: ''
}

const fonts: Record<TypographyAlias, string> = {
  body: '',
  heading: '',
  monospace: 'monospace'
}

const fontWeights: Record<TypographyAlias, string> = {
  body: '',
  heading: '',
  monospace: ''
}

const lineHeights: Record<TypographyAlias, string> = {
  body: '',
  heading: '',
  monospace: ''
}

const text: any = {
  "data-value": {
    fontFamily: "monospace",
    fontWeight: '300',
    fontSize: 1
  },
  caps: {
    textTransform: 'uppercase',
    letterSpacing: "0.2rem"
  },
  heading: {
    fontFamily: 'heading',
    fontWeight: '300',
    lineHeight: 'heading',
    fontSize: 4
  },
  secondary: {
    fontFamily: 'heading',
    fontWeight: '500',
    lineHeight: 'heading',
    fontSize: 3
  },
  tertiary: {
    fontFamily: 'heading',
    fontWeight: '800',
    lineHeight: 'heading',
    fontSize: 1
  },
  "data-label": {
    fontFamily: 'heading',
    fontWeight: '800',
    lineHeight: 'heading',
    textTransform: 'uppercase',
    letterSpacing: "0.05rem",
    color: "#434343",
    fontSize: 0
  }
  
}

const baseButtonTheme = {
  py: 5,
  fontWeight: 500,
  textTransform: "uppercase",
  letterSpacing: "0.05rem",
}

export const tokens: ThemeProps = {
  colors,
  fonts,
  fontSizes,
  space,
  mediaQueries,
  breakpoints,
  lineHeights,
  fontWeights,
  text,
  buttons: {
    primary: {
      ...baseButtonTheme,
      color: "background",
      background: "primary",
      cursor: "pointer",
      borderRadius: 8,
      transition: "background-color 200ms, color 100ms",
      '&:hover, &:focus': {
        background: darken('primary', 0.25)
      },
      '&:disabled': {
        background: "#c4c4c4",
        color: "black"
      }
    },
    ghost: {
      ...baseButtonTheme,
      background: "rgba(0,0,0,0)",
    }
  },
  cards: {
    primary: {
      padding: "24px",
      borderRadius: "8px",
      boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.15)",
      background: "white",
    }
  }
}
export type DesignTokens = typeof tokens

// selectors

type ThemeProps = Record<string, any> & { theme?: DesignTokens }
export const selectColor = (color: keyof typeof colors) => (props: ThemeProps) => props.theme?.colors?.[color]
export const selectFonts = (font: keyof typeof fonts) => (props: ThemeProps) => props.theme?.fonts?.[font]
export const selectFontSize = (fontSize: number) => (props: ThemeProps) => props.theme?.fontSizes?.[fontSize]
export const selectSpace = (key: number | SpaceAlias ) => (props: ThemeProps) => props.theme?.space?.[key]

// utils

export const inspectStyledProps = (props: any) => {
  console.log({ props })
  return ""
}
