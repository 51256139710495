import dotenv from "dotenv"
dotenv.config()

interface Config {
  env: string
  api: {
    url: string
  }
  auth: {
    domain: string
    clientId: string
    audience: string
    baseNameSpace: string
  }
  blinkIdKey: string
  isProd: boolean
  tagManager: {
    analyticsId: string
  }
  rapidapi: {
    host: string
    key: string
  }
}

const defaults = {
  env: "development",
  api: {
    url: process.env.REACT_APP_API_URL ?? "https://api.dev.getcheckapp.com",
  },
  blinkIdKey: process.env.REACT_APP_BLINKID_KEY ?? "sRwAAAYbZHJpdmVycy5kZXYuZ2V0Y2hlY2thcHAuY29t5gy156kxzd6erE3Ry435yApGaRf77vPQ581h81Vsdu84PGZtmTUmpnFa7J6QrljTPSjIpd1/TpK0uijVtoDn85pM4Z3e0N7XZwJq0ubIgRLrmtbPn29Zn+Eqol60I4gRCSAxdlvIgetvmgno4KsNscQ4lgE+ME4NZhXXOcU/FiYMux9WByx5bC9k9KeBYOYXaiwTbpSlkFwVu44gpabGEGZ6us0p0iPuzDelKgUGvdqH7kh8xkQ2D7hQnBVeLANlDwMuIhIMAiN/rXzEC14Llzi6DQInYFDLiOD2R7bX9iosKXgzr1cU0LD/TJIKnnyBkyO704PY",
  auth: {
    domain: process.env.REACT_APP_AUTH_DOMAIN ?? "dev-pllg2po5.us.auth0.com",
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? "N7QmRA83m3QG8te32zEGlo9bHuDh15QG",
    audience: process.env.REACT_APP_AUTH_AUDIENCE ?? "https://api.getcheckapp.com/",
    baseNameSpace: process.env.REACT_APP_AUTH_BASE_NAMESPACE ?? "https://api.getcheckapp.com",
  },
  tagManager: {
    analyticsId: process.env.REACT_APP_TAG_MANAGER ?? ""
  },
  rapidapi: {
    host: process.env.REACT_APP_RAPID_HOST || "us-license-plate-to-vin.p.rapidapi.com",
    key: process.env.REACT_APP_RAPID_KEY || "d9b372aa33msh467e98b1d8dbd72p12d33fjsneb9cffdd807e",
  },
}

const isProd = process.env.NODE_ENV === "production"

console.log("APIURL", process.env.REACT_APP_API_URL)

const config = {
  env: isProd ? "production" : defaults.env,
  isProd: isProd,
  blinkIdKey: defaults.blinkIdKey,
  auth: {
    domain: process.env.AUTH_DOMAIN ?? defaults.auth.domain,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? defaults.auth.clientId,
    audience: process.env.REACT_APP_AUTH_AUDIENCE ?? defaults.auth.audience,
    baseNameSpace: process.env.REACT_APP_AUTH_BASE_NAMESPACE ?? defaults.auth.baseNameSpace,
  },
  api: {
    url: process.env.REACT_APP_API_URL ?? defaults.api.url,
  },
  tagManager: {
    analyticsId: process.env.REACT_APP_TAG_MANAGER ?? defaults.tagManager.analyticsId,
  },
  rapidapi: {
    host: defaults.rapidapi.host,
    key: defaults.rapidapi.key,
  }
}

export default config as Config
