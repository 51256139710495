
import React from 'react'
import { Box, Grid, Heading, Image } from '@theme-ui/components'

export interface InsuranceInfoProps {
  data: any
}


export const InsuranceInfo =({ data }: InsuranceInfoProps) => {
  return (
    <Grid gap={4}>
      <Heading variant="secondary">
        Proof of Insurance
      </Heading>
      <Box sx={{
        borderRadius: "8px",
        overflow: "hidden"
      }}>
        <Image
          sx={{
            display: "block"
          }}
          src={data.insurance.insuranceCardImageUrl}
        />
      </Box>
    </Grid>
  )
}