import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { DetailedHTMLProps, InputHTMLAttributes, useRef, useState } from 'react'
import { DesignTokens, selectColor, selectFonts, selectFontSize, selectSpace } from './tokens'




export type TextInputProps = {
  label: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const TextInput: React.FC<TextInputProps> = ({ label, ...inputProps }) => {

  const [isFocused, setIsFocused] = useState<boolean>(false)
  const labelIsActive = !!inputProps.value || isFocused
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <_Root onBlur={() => setIsFocused(false)} onFocus={() => setIsFocused(true)} isActive={isFocused}>
      <_Label isActive={labelIsActive} onClick={() => inputRef?.current?.focus?.()}>
        {label}
      </_Label>
      <StyledInput  {...inputProps} ref={inputRef} type="text"/>
    </_Root>
  )
}

// const _Root = styled.label`
//   overflow: hidden;
//   border: #c4c4c4 2px solid;
//   position: relative;
// `

type _RootProps = {
  theme?: DesignTokens,
  isActive?: boolean
}

const _Root = styled.label<_RootProps>`
  border-radius: ${selectSpace(2)};
  overflow: hidden;
  border: #ddd 2px solid;
  position: relative;
  ${props => props.isActive ? css`
    border-color: ${selectColor("primary")(props)};
  ` : css``}
`

type _LabelProps = {
  theme?: DesignTokens,
  isActive?: boolean
}

const _Label = styled.span<_LabelProps>`
  position: absolute;
  top: ${selectSpace(6)};
  left: ${selectSpace(6)};
  color: #c4c4c4;
  transition: transform 50ms;
  transform-origin: "top left";
  ${props => props.isActive ? css`
    transform: scale(0.75) translate(-17%, -90% );
  ` : css``}

`

// export const StyledInput = styled.input`
//   padding: 32px 24px 16px;  
//   border: none;
//   background: none;
//   display: block;
//   width: 100%;
//   outline: none;
// `

export const StyledInput = styled.input`
  padding: ${selectSpace(8)} ${selectSpace(6)} ${selectSpace(4)};
  border: none;
  background: none;
  display: block;
  width: 100%;
  font-size: ${selectFontSize(2)};
  border-radius: ${selectSpace(4)};
  outline: none;
  font-family: ${selectFonts("body")};
`