import React from 'react'
import styled from '@emotion/styled'
import Topbar from './TopBar'


const S = {
  Base: styled.div`
    /* display: grid; */
  `,
  Header: styled.header`
    position: sticky;
    top: 0;
    z-index: 500;
  `,
  Main: styled.main``,
  Footer: styled.footer``
}

export const PageLayout: React.FC = ({ children }) => {
  return (
    <S.Base>
      <S.Header>
        <Topbar />
      </S.Header>
      <S.Main>
        <Container>
          { children }
        </Container>
      </S.Main>
      <S.Footer>

      </S.Footer>
    </S.Base>
  )
} 

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-top: 44px;
  max-width: 560px;
  
`