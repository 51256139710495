import { getRequest } from ".."

export interface QueryTrafficStopOpts {
  plate: string
}

export const queryTrafficStop = async ({ plate }: QueryTrafficStopOpts) => {
  const url = `/police/stop/${plate}`
  const response = await getRequest<any>(url, {})
  return response
}