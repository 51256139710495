import { getRequest } from ".."

export interface QueryTokenOpts {
  shortAlias: string
}

export const queryActualToken = async ({ shortAlias }: QueryTokenOpts) => {
  const url = `/police/stop/token/${shortAlias}`
  const response = await getRequest<any>(url, {})
  console.log(response)
  return response
}