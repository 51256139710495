import styled from '@emotion/styled'
import React from 'react'
import { selectSpace, selectColor, selectFontSize, selectFonts, inspectStyledProps, DesignTokens } from "./tokens"

// base

type _BaseButtonProps = {
  compact?: boolean,
  theme?: DesignTokens
}

export const _BaseButton = styled.button<_BaseButtonProps>`
  font-family: ${selectFonts('body')};
  text-transform: uppercase;
  border: none;
  background: none;
  padding: ${props => props.compact ? selectSpace(3)(props) : selectSpace(6)(props)};
  border-radius: ${selectSpace(4)};
  font-size: ${selectFontSize(2)};
  letter-spacing: 0.05em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &:disabled {
    color: grey;
    background: #c4c4c4;
    cursor: not-allowed;
  }
`

// variants

export const PrimaryButton = styled(_BaseButton)`
  color: #fff;
  background: ${selectColor('primary')};
  background: linear-gradient(86.74deg, #3626A7 0%, #3C28C9 100%);
  box-shadow: 0px 4px 4px rgba(135, 124, 211, 0.67);
  transition: background 300ms, box-shadow 300ms;
  cursor: pointer;
  outline: none;
  &:hover, &:focus {
    box-shadow: 0px 6px 6px rgba(135, 124, 211, 0.67);
  }
  &:disabled {
    background: #c4c4c4;
    box-shadow: none;
    cursor: not-allowed;
  }
`

export const SecondaryButton = styled(_BaseButton)`
  color: ${selectColor('primary')};
  background: #fff;
  box-shadow: 0px 4px 4px rgba(173, 170, 187, 0.67);
  
`

export const GhostButton = styled(_BaseButton)``