import React from 'react';
import ReactDOM from 'react-dom';
import { Global, css, ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import { QueryClient, QueryClientProvider } from "react-query"
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { tokens } from "./foundations/tokens"

import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-html5-camera-photo/build/css/index.css"
import { Auth0Provider } from '@auth0/auth0-react';
import config from './config';

const queryClient = new QueryClient()

const globalStyles = css`
  // fonts
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
  // global styles
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }
  body {
    font-size: 18px;
    /* background: rgb(242, 239, 228); */
  }
  
`
const AllProviders: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <EmotionThemeProvider theme={tokens}>
        <Global styles={globalStyles} />
          <Auth0Provider
            domain={config.auth.domain}
            clientId={config.auth.clientId}
            redirectUri={`${window.location.origin}/dashboard`}
            // audience={`api.getcheckapp.com`}
          >
            <QueryClientProvider client={queryClient} >
              <React.StrictMode>
                {children}
              </React.StrictMode>
            </QueryClientProvider>
          </Auth0Provider>
      </EmotionThemeProvider>
    </React.Fragment>
  )
}

ReactDOM.render(
  <AllProviders><App /></AllProviders>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
