import { makeStyles } from "@material-ui/core/styles"
import styled from "@emotion/styled"
import { Card } from "../../foundations/Card"
import { selectColor, selectSpace } from "../../foundations/tokens"
import { Grid, Button, Heading, Text } from "@theme-ui/components"
import { TextInput } from "../../foundations/Input"
import { useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#2F229D",
    borderRadius: "15px",
    boxShadow: "20px 20px 100px #2f229d",
  },
  title: {
    fontSize: 20,
    textAlign: "left",
    color: "#fff",
    fontWeight: 900,
    padding: "10px",
  },
  subtitle: {
    fontSize: 15,
    textAlign: "center",
    color: "#fff",
    fontWeight: 200,
    paddingBottom: "30px"
  },
  button: {
    fontSize: 15,
    fontWeight: 400,
    background: "#fff",
    color: "#2f229d",
    borderRadius: "10px",
    width: "90%",
    margin: "0 auto 10px",
    display: "block",
    marginBottom: "10",
    '&:hover': {
        backgroundColor: '#fff',
        borderColor: '#fff',
        boxShadow: 'none',
    },
  },
}))

interface LicensePlateSearchProps {
  onNotifyClick: (licensePlate: string) => any
}

const LicensePlateSearch = ({ onNotifyClick }: LicensePlateSearchProps) => {
  const [licensePlate, setLicensePlate] = useState("")
  const { user } = useAuth0()

  return (
    <LicensePlateSearchCard>
      <Grid gap={6}>
        <Grid gap={4} sx={{ zIndex: 50 }}>
          <Heading sx={{ fontSize: 4 }}>
            Pulled Someone Over?
          </Heading>
          <Text sx={{ fontSize: 2 }}>
            Invite them to a virtual traffic stop by entering their license plate.
          </Text>
        </Grid>

        <Grid gap={4}>

          <TextInput 
            label="License Plate"
            color="background"
            onChange={(e) => setLicensePlate(e.target.value)}
          />

          <Button sx={{
            color: "primary",
            textTransform: "uppercase",
            py: 7,
            fontWeight: "300",
            fontSize: 4,
            bg: "white",
            zIndex: 25
          }} variant="ghost" onClick={() => onNotifyClick(licensePlate)}>
            Start Traffic Stop
          </Button>
        </Grid>
        
      </Grid >
    </LicensePlateSearchCard>
  )
}

const LicensePlateSearchCard = styled(Card)`
  background: ${selectColor('primary')};
  background: linear-gradient(86.74deg, #3626A7 0%, #3C28C9 100%);
  box-shadow: 0px 4px 4px rgba(135, 124, 211, 0.67);
  color: white;
  padding: ${selectSpace(6)};
`

export default LicensePlateSearch